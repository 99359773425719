<template>
  <div :id="`section_${id}`" class="section w-full py-10" :class="`bg-${background}`">
    <div class="section-inner mx-auto px-5">
      <template v-if="elements">
        <template v-for="(element, key) of elements" :key="key">
          <PageOpener v-if="element.__component === 'content.page-opener'" :data="element" />
          <Teaser v-if="element.__component === 'content.teaser'" :href="element.Href" :type="element.Type" :title="element.Title" :text="element.Text" :headline="element.Headline"/>
          <Text v-if="element.__component === 'content.text'" :text="element.Text" :headline="element.Headline" :subheadline="element.Subheadline" :headline_color="element.HeadlineColor" :border="element.Border" />
          <Video v-if="element.__component === 'content.video'" :source="element.Source" :videoId="element.VideoID" />
          <Buttons v-if="element.__component === 'content.button'" :content="element.content" :type="element.type" :href="element.href" :icon="!!element.icon" :icon_type="element.icon" :icon_weight="element.icon_weight" :color="element.color" />
          <ButtonGroup v-if="element.__component === 'content.button-group'" :buttons="element.Buttons" :type="element.type" :headline="element.headline" :background="element.background" :headlineType="element.headlineType" />
          <Contact v-if="element.__component === 'content.contact-button'" :type="element.type" :label="element.label" :href="element.url" :content="element.content"/>
          <AccordionGroup v-if="element.__component === 'content.accordion-group'" :type="element.Type" :headerType="element.HeaderType" :headline="element.Headline" :headline_color="element.HeadlineColor" :accordions="element.Accordions" />
          <Advisory v-if="element.__component === 'content.advisory'" :data="element.beratung"/>
          <ContactGroup v-if="element.__component === 'content.contact-group'" :buttons="element.Buttons" :headline="element.Headline" />
          <Headline v-if="element.__component === 'content.headline'" :text="element.Content" :type="element.Type" classes="subline3 mb-2.5" />
          <Map v-if="element.__component === 'content.map'" :mapUrl="element.MapUrl" />
          <Image v-if="element.__component === 'content.image'" :image="element.Image.data.attributes" />
        </template>
      </template>
      <slot />
    </div>
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";
const PageOpener = defineAsyncComponent(() => import('@/modules/PageOpener'));
const Teaser = defineAsyncComponent(() => import('@/components/Teasers'));
const Text = defineAsyncComponent(() => import('@/modules/Text'));
const Video = defineAsyncComponent(() => import('@/modules/Video'));
const Buttons = defineAsyncComponent(() => import('@/components/Buttons'));
const ButtonGroup = defineAsyncComponent(() => import('@/modules/ButtonGroup'));
const Contact = defineAsyncComponent(() => import('@/modules/Contact'));
const AccordionGroup = defineAsyncComponent(() => import('@/modules/AccordionGroup'));
const Advisory = defineAsyncComponent(() => import('@/modules/Advisory'));
const ContactGroup = defineAsyncComponent(() => import('@/modules/ContactGroup'));
const Headline = defineAsyncComponent(() => import('@/components/Headline'));
const Map = defineAsyncComponent(() => import('@/modules/Map'));
const Image = defineAsyncComponent(() => import('@/modules/Image'));
export default {
  name: "Section",
  props: ["id", "background", "elements", "data"],
  components: {
    PageOpener,
    Teaser,
    Text,
    Video,
    Buttons,
    ButtonGroup,
    Contact,
    AccordionGroup,
    Advisory,
    ContactGroup,
    Headline,
    Map,
    Image
  },
  data() {
    return {

    }
  },
  created() {

  }
}

</script>

<style lang="scss">
.section-inner {

  max-width: var(--section-width);

  & > div:last-child,
  & > a:last-child {

    margin-bottom: 0;
  }
}

.section:first-child {

  margin-top: 2.5rem;
  padding-top: 0;

  .section-inner {

    position:relative;
    top: -1rem;
    margin-bottom: -1rem;
  }
}
</style>
